<template>
  <b-card class="mb-4">
    <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
      <div class="align-self-lg-center flex-fill" v-if="!selected || selected.id !== (value && value.id)">
        <slot name="placeholder">
          <p class="h3 font-weight-bold text-muted">Lead Generated By</p>
          <p class="mb-0 text-muted">Who generated this lead?</p>
        </slot>
      </div>
      <div class="align-self-lg-center flex-fill mr-2 minw-0" v-if="(selected && selected.id) === (value && value.id)">
        <slot name="selected" :selected="selected">
          <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ [selected && selected.forename, selected && selected.surname].join(' ') }}</p>
          <p class="mb-0 text-muted">{{ selected && selected.email }}</p>
        </slot>
      </div>
      <div class="d-flex flex-column mt-2 mt-lg-0">
        <b-button block class="my-1" variant="secondary" v-b-modal.assign-leadgen>Assign Rep</b-button>
        <b-button block class="my-1" variant="primary" @click.prevent="selected = currentUser; $emit('input', currentUser)">Assign Myself</b-button>
      </div>
    </b-card-text>
    <b-modal id="assign-leadgen" size="lg" cancel-title="Close" ok-title="Assign"
    title="Assign Lead Generator" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selected"
    @ok="$emit('input', selected)" body-class="p-0">
      <div class="p-3 pb-0">
        <b-form-group class="mb-0" label="User Search" label-for="user-search" label-sr-only>
          <b-form-input debounce="1000" @update="fetch({ query: query })" id="user-search" v-model="query" v-b-popover.hover.top="'Search for sales representatives.'" />
        </b-form-group>
      </div>
      <div class="text-center my-4" v-if="searching">
        <b-spinner variant="secondary" />
      </div>
      <b-list-group class="border-top" flush v-if="!searching && results && results.length">
        <b-list-group-item button @click.prevent="selected = user" :class="{'bg-selected': user.id === (selected && selected.id)}" :key="user.id" v-for="user in results">
          <div class="d-flex flex-column flex-fill">
            <slot name="result" :user="user">
              <p class="h5 flex-fill mb-0 text-primary">{{ [user && user.forename, user && user.surname].join(' ') }}</p>
              <p class="text-muted mb-0"><small>{{ user && user.email }}</small></p>
            </slot>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import currentUser from '../mixins/currentUser'

export default {
  mixins: [currentUser],
  props: {
    value: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      selected: null,
      query: ''
    }
  },
  mounted () {
    if (this.value) {
      this.selected = this.value
    }
  },
  computed: {
    results () {
      return this.$store.getters['users/data']
    },
    searching () {
      return this.$store.getters['users/isLoading']
    }
  },
  methods: {
    ...mapActions({
      fetch (dispatch, payload) {
        return dispatch('users/fetchManyUsers', payload)
      }
    })
  },
  watch: {
    value (value) {
      if (value) {
        this.selected = { ...this.selected, ...value }
      }
    }
  }
}
</script>
