<template>
  <router-view />
</template>

<script>
import customerModule from '../../store/security-customers'
import inventoryModule from '../../store/inventory'
import siteModule from '../../store/security-sites'
import userModule from '../../store/users'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('security-customers')) {
      this.$store.registerModule('security-customers', customerModule)
    } if (!this.$store.hasModule('inventory')) {
      this.$store.registerModule('inventory', inventoryModule)
    } if (!this.$store.hasModule('security-sites')) {
      this.$store.registerModule('security-sites', siteModule)
    } if (!this.$store.hasModule('users')) {
      this.$store.registerModule('users', userModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('security-customers')) {
      this.$store.unregisterModule('security-customers')
    } if (this.$store.hasModule('inventory')) {
      this.$store.unregisterModule('inventory')
    } if (this.$store.hasModule('security-sites')) {
      this.$store.unregisterModule('security-sites')
    } if (this.$store.hasModule('users')) {
      this.$store.unregisterModule('users')
    }
  }
}
</script>
