<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Site</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row class="mb-4">
            <b-col cols="12">
              <assign-customer type="security" v-model="selectedCustomer">
                <template #placeholder>
                  <p class="h3 font-weight-bold text-muted">Assign Customer</p>
                  <p class="mb-0 text-muted">The customer to assign a security site to.</p>
                </template>
              </assign-customer>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2 class="font-weight-bold h4">Site Address</h2>
                </b-col>
                <b-col class="mb-2" cols="12" md="4">
                  <b-button block @click.prevent="address = selectedCustomer.address" :disabled="!selectedCustomer" variant="primary">Copy Customer Address</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Address Line 1" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_1')" :state="validationState(errors, 'address.address_line_1')">
                    <b-form-input :disabled="isSaving" v-model="address.address_line_1" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Address Line 2" :invalid-feedback="validationInvalidFeedback(errors, 'address.address_line_2')" :state="validationState(errors, 'address.address_line_2')">
                    <b-form-input :disabled="isSaving" v-model="address.address_line_2" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="City" :invalid-feedback="validationInvalidFeedback(errors, 'address.city')" :state="validationState(errors, 'address.city')">
                    <b-form-input :disabled="isSaving" v-model="address.city" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="County" :invalid-feedback="validationInvalidFeedback(errors, 'address.county')" :state="validationState(errors, 'address.county')">
                    <b-form-input :disabled="isSaving" v-model="address.county" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                  <b-form-group label="Postcode" :invalid-feedback="validationInvalidFeedback(errors, 'address.postcode')" :state="validationState(errors, 'address.postcode')">
                    <b-form-input :disabled="isSaving" v-model="address.postcode" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <assign-user v-model="selectedUser">
                <template #placeholder>
                  <p class="h3 font-weight-bold text-muted">Responsibility of Sales Rep</p>
                  <p class="mb-0 text-muted">A sales representative is responsible for the upkeep of this security site.</p>
                </template>
              </assign-user>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row class="mb-2">
                <b-col cols="12" md="8"><h2>Deals</h2></b-col>
                <b-col cols="12" md="4"><b-button block @click.passive="selectedDeal = null" variant="primary" v-b-modal.edit-deal>Add Deal</b-button></b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <deals-table @edit="editDeal" :value="deals" />
                  <edit-deal-modal id="edit-deal" @update="updateDeal" v-model="selectedDeal" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Contacts</h2>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block @click.passive="editContact = {}" variant="primary" v-b-modal.edit-contact>Add Contact</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['name', 'email', 'phone', 'actions']" :items="contacts">
                    <template v-slot:cell(name)="row">
                      <p class="mb-0 text-nowrap">{{ [row.item.surname.toUpperCase(), row.item.forename].join(' ') }}</p>
                      <p class="mb-0 text-nowrap"><em>{{ row.item.position }}</em></p>
                    </template>
                    <template v-slot:cell(phone)="row">
                      <p class="mb-0" v-if="row.item.landline"><strong>Landline:</strong> {{ row.item.landline }}</p>
                      <p class="mb-0" v-if="row.item.mobile"><strong>Mobile:</strong> {{ row.item.mobile }}</p>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2 text-nowrap">{{ row.detailsShowing ? 'Hide' : 'Show'}} Details</b-button>
                    </template>

                    <template v-slot:row-details="row">
                      <b-card>
                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Landline Phone Number:</strong></b-col>
                          <b-col>{{ row.item.landline || 'N/A' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Mobile Phone Number:</strong></b-col>
                          <b-col>{{ row.item.mobile || 'N/A' }}</b-col>
                        </b-row>

                        <b-row class="mb-2">
                          <b-col sm="3" class="text-sm-right"><strong>Notes:</strong></b-col>
                          <b-col>{{ row.item.notes || 'N/A' }}</b-col>
                        </b-row>

                        <b-button variant="secondary" size="sm" @click="row.toggleDetails" class="mr-2">Hide Details</b-button>
                        <b-button variant="primary" size="sm" @click="showEditContact(row.index)" class="mr-2">Edit Contact</b-button>
                        <b-button variant="danger" size="sm" @click="removeContact(row.index)" class="mr-2">Remove</b-button>
                      </b-card>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
            <b-modal id="edit-contact" size="lg" cancel-title="Close" :ok-title="editContact.index < -1 ? 'Save' : 'Create'"
              :title="editContact.index < -1 ? 'Edit Contact' : 'Create Contact'" ok-variant="secondary" cancel-variant="dark"
              @ok="addContact" @cancel="cancelContact" :ok-disabled="contactSubmitDisabled">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Forename">
                    <b-form-input v-model="editContact.forename" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Surname">
                    <b-form-input v-model="editContact.surname" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Position">
                    <b-form-input v-model="editContact.position" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Email Address">
                    <b-form-input v-model="editContact.email" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Landline Number">
                    <b-form-input v-model="editContact.landline" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Mobile Number">
                    <b-form-input v-model="editContact.mobile" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Contact Notes">
                    <b-form-textarea v-model="editContact.notes" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>
          </b-row>

          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8"><h2>Site Notes</h2></b-col>
                <b-col cols="12" md="4"><b-button block :disabled="!$route.params.id" variant="primary" v-b-modal.add-customer-note v-if="currentUserCan(permissions.CAN_WRITE_SECURITY_CUSTOMER_NOTES)">Add Note</b-button></b-col>
                <b-col class="mt-2" cols="12">
                  <b-card class="py-2 text-center" v-if="!currentUserCan(permissions.CAN_READ_SECURITY_CUSTOMER_NOTES)">
                    <p class="mb-2"><b-icon class="display-1 text-dark" icon="cloud-slash"></b-icon></p>
                    <p class="font-weight-normal h5 mb-0 text-dark">Can not view site notes (you do not have permission).</p>
                  </b-card>
                </b-col>

                <b-col class="mt-2" cols="12">
                  <b-table v-if="currentUserCan(permissions.CAN_READ_SECURITY_CUSTOMER_NOTES)" responsive bordered striped head-variant="dark" :fields="['content', { key: 'created_at', label: 'Created' }, 'created_by']" :items="notes">
                    <template v-slot:cell(created_at)="row">
                      <friendly-date class="mb-0" :date="row.item.created_at" />
                    </template>
                    <template v-slot:cell(created_by)="row">
                      {{ [row.item.created_by.forename, row.item.created_by.surname].join(' ') }}
                    </template>
                  </b-table>
                </b-col>

                <b-modal v-if="currentUserCan(permissions.CAN_WRITE_ENERGY_CUSTOMER_NOTES)" id="add-customer-note" size="lg" cancel-title="Close"
                  title="Add Customer Note" ok-variant="secondary" cancel-variant="dark"
                  @ok.prevent="createCustomerNote" @cancel="cancelCustomerNote">
                  <template v-slot:modal-ok>
                    <span v-if="!isSavingNote">Create</span><b-spinner class="mr-2" small variant="light" v-if="isSavingNote" />
                  </template>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group class="mb-0" label="Customer Note">
                        <b-form-textarea :disabled="isSavingNote" rows="6" v-model="addCustomerNote" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-modal>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8"><h2>Documents</h2></b-col>
                <b-col cols="12" md="4"><b-button block :disabled="!$route.params.id" variant="primary" v-b-modal.upload-document>Upload Document</b-button></b-col>
              </b-row>

              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['filename', { key: 'url', label: 'URL' }, { key: 'created_at', label: 'Uploaded At' }]" :items="documents">
                    <template v-slot:cell(url)="row">
                      <a :href="downloadURL(row.item.url)">Download</a>
                    </template>

                    <template v-slot:cell(created_at)="row">
                      <friendly-date class="mb-0" :date="row.item.created_at"/>
                      <p class="mb-0"><em>{{ row.item.created_by ? `by ${[row.item.created_by.forename, row.item.created_by.surname].join(' ')}` : '' }}</em></p>
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-modal id="upload-document" cancel-variant="dark" ok-variant="secondary" title="Upload Document" size="md"
               @ok.prevent="submitUploadDocument" @cancel="cancelUploadDocument">
                <template v-slot:modal-ok>
                  <span v-if="!isUploading">Upload</span><b-spinner class="mx-4" small variant="light" v-if="isUploading" />
                </template>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Upload Document">
                      <b-form-file v-model="uploadDocumentFile" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col cols="12" md="4" offset-md="8">
              <b-button block class="mb-3" :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import currentUser from '../../mixins/currentUser'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import normalizeURL from 'normalize-url'

import DealsTable from './DealsTable.vue'
import EditDealModal from './EditDealModal.vue'
import FriendlyDate from '../../components/FriendlyDate.vue'
import AssignCustomer from '../../components/AssignCustomer.vue'
import AssignUser from '../../components/AssignUser.vue'

const initialData = () => ({
  customer_id: 0,
  address: {
    address_line_1: null,
    address_line_2: null,
    city: null,
    county: null,
    postcode: null
  },
  documents: [],
  contacts: [],
  deals: [],

  addCustomerNote: '',
  customerSearchQuery: '',
  selectedCustomer: null,
  selectedUser: null,
  selectedDeal: null,
  editContact: {},
  uploadDocumentFile: null
})

export default {
  mixins: [currentUser, validation],
  components: { DealsTable, EditDealModal, FriendlyDate, AssignCustomer, AssignUser },
  created () {
    if (this.$route.params.id) {
      this.fetchSite(this.$route.params.id)
    }

    if (this.$route.query.customer) {
      this.fetchCustomer(this.$route.query.customer).then(cust => {
        this.selectedCustomer = cust.data
        this.customer_id = cust.data.id
      })
    }
  },
  data () {
    return initialData()
  },
  beforeRouteLeave (_0, _1, next) {
    this.$store.dispatch('security-sites/reset')
    Object.assign(this.$data, initialData())
    return next()
  },
  computed: {
    ...mapGetters('security-customers', { customerSearchResults: 'data', isSearching: 'isLoading' }),
    ...mapGetters('security-sites', ['errors', 'isLoading', 'isSaving', 'isSavingNote', 'isUploading', 'single']),
    adminEmail () {
      return process.env.VUE_APP_ADMIN_EMAIL
    },
    emailAllLink () {
      const contacts = this.contacts.map(c => `${c.forename} ${c.surname} <${c.email}>`)
      return `mailto:${contacts.join(',')}?bcc=${this.adminEmail}`
    },
    contactSubmitDisabled () {
      return !this.editContact.forename || !this.editContact.surname ||
             !this.editContact.email || !(
        (this.editContact.landline && this.editContact.landline.length === 11) ||
        (this.editContact.mobile && this.editContact.mobile.length === 11)
      )
    }
  },
  methods: {
    ...mapActions('security-customers', ['fetchCustomer', 'fetchManyCustomers']),
    ...mapActions('security-sites', ['fetchSite', 'createNote', 'saveSite', 'uploadDocument']),
    downloadURL (path) {
      return path[0] === '/' ? normalizeURL(process.env.VUE_APP_API_URL + path) : path
    },
    submitUploadDocument () {
      const formData = new FormData()
      formData.append('file', this.uploadDocumentFile, this.uploadDocumentFile.name)
      this.uploadDocument({ id: this.$route.params.id, data: formData }).then(res => {
        this.documents.unshift(res.data)
        this.uploadDocumentFile = null
        this.$bvModal.hide('upload-document')
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Document uploaded successfully',
          text: 'The document has been assigned to this site.'
        })
      }).catch(err => {
        if (err.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Error uploading document',
            text: `Error for debugging: ${err.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    },
    cancelUploadDocument () {
      this.uploadDocumentFile = null
    },
    createCustomerNote () {
      this.createNote({
        siteId: this.$route.params.id,
        content: this.addCustomerNote
      }).then(response => {
        this.notes.unshift(response.data)
        this.addCustomerNote = ''
        this.$bvModal.hide('add-customer-note')
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Site Note created successfully',
          text: 'This security site has successfully had a note added to it.'
        })
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Site Note`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    },
    cancelCustomerNote () {
      this.addCustomerNote = ''
    },
    editDeal (index) {
      this.selectedDeal = this.deals[index]
      this.$bvModal.show('edit-deal')
    },
    updateDeal (deal) {
      if (deal.id) {
        const found = this.deals.find(predicate => deal.id === predicate.id)
        this.deals.splice(this.deals.indexOf(found), 1, deal)
      } else {
        this.deals.push(deal)
      }

      this.selectedDeal = null
      this.$bvModal.hide('edit-deal')
    },

    addContact () {
      if (!this.editContact.index) {
        this.contacts.push(this.editContact)
        this.editContact = {}
      } else {
        this.contacts[this.editContact.index] = this.editContact
        this.editContact = {}
      }
      this.$bvModal.hide('edit-contact')
    },
    cancelContact () {
      this.editContact = {}
    },
    removeContact (index) {
      this.contacts.splice(index, 1)
    },
    showEditContact (index) {
      this.editContact = { ...this.contacts[index], index }
      this.contacts[index] = this.editContact
      this.$bvModal.show('edit-contact')
    },
    submit () {
      this.saveSite({
        id: this.$route.params.id,
        assigned_rep_id: this.selectedUser && this.selectedUser.id,
        customer_id: this.selectedCustomer && this.selectedCustomer.id,

        address: this.address,
        contacts: this.contacts,
        deals: this.deals.map(d => ({
          ...d,
          deduction: (d.deduction === null || d.deduction === undefined)
            ? 0
            : (typeof d.deduction === 'number' ? d.deduction : d.deduction.value)
        }))
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Site ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This security site has successfully been saved.'
        })

        if (!this.$route.params.id) {
          return this.$router.push({ name: 'security.sites.update', params: { id: response.data.id } })
        }

        return this.fetchSite(this.$route.params.id)
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Site`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    single (value) {
      this.ced = value.ced
      this.aged_debt = value.aged_debt
      this.current_debt = value.current_debt

      this.deals = (value && value.deals) || []
      this.address = (value && value.address) || {}
      this.contacts = (value && value.contacts) || []
      this.documents = (value && value.files) || []
      this.notes = (value && value.notes) || []

      this.customer_id = value.customer?.id
      this.selectedUser = (value && value.assigned_rep) || null
      this.selectedCustomer = (value && value.customer) || null
    }
  }
}
</script>

<style>

</style>
