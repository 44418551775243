<template>
  <b-modal :id="id" size="xl" title="Add/Update Deal"
    ok-title="Submit" ok-variant="secondary" @ok="submitDeal"
    cancel-title="Close" cancel-variant="primary" :ok-disabled="items && items.length === 0">
    <b-row class="my-3">
      <b-col cols="12">
        <b-table bordered responsive striped head-variant="dark" :fields="fields" :items="items">
          <template #cell(name)="{ item }">
            {{ item.inventory && item.inventory.name }}
          </template>
          <template #cell(_)="{ index }">
            <b-button block @click.prevent="removeItem(index)" variant="danger">Remove</b-button>
          </template>
          <template #bottom-row>
            <th class="align-middle"><inventory-search class="align-self-center" return-object v-model="newDealProduct" /></th>
            <th class="align-middle"><b-form-input class="align-self-center" min="1" type="number" v-model.number="newDealItem.quantity" /></th>
            <th class="align-middle"><b-form-input class="align-self-center" step="0.01" :min="newDealProduct.price" type="number" v-model.number="newDealItem.price" /></th>
            <th class="align-middle"><b-form-input class="align-self-center" disabled type="number" v-model.number="newDealItem.revenue" /></th>
            <th class="align-middle" v-if="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)"><b-form-input class="align-self-center" disabled type="number" v-model.number="newDealItem.profit" /></th>
            <th class="align-middle"><b-button block class="align-self-center text-nowrap" @click.prevent="addItem" :disabled="newDealItem.quantity < 1" variant="secondary">Add Item</b-button></th>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="Start Date">
          <b-form-datepicker v-model="start_date" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="End Date">
          <b-form-datepicker v-model="end_date" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Monthly Cost">
          <b-input-group prepend="£">
            <b-form-input step="0.01" type="number" v-model="monthlyCost" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="3" v-if="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)">
        <b-form-group label="Customer Cost Over">
          <b-input-group prepend="£">
            <b-form-input disabled type="text" v-model="totalRevenue" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3" v-if="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)">
        <b-form-group label="Profit">
          <b-input-group prepend="£">
            <b-form-input disabled type="text" v-model="totalProfit" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3" v-if="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)">
        <b-form-group label="Estimated Commission">
          <b-input-group prepend="£">
            <b-form-input disabled type="text" v-model="totalCommission" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="3" v-if="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)">
        <b-form-group label="Deduction">
          <b-input-group prepend="£">
            <b-form-input step="0.01" type="number" v-model="deduction" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import permissions from '../../permissions.json'
import currentUser from '../../mixins/currentUser'
import InventorySearch from '../../components/InventorySearch.vue'
import moment from 'moment'
import { mapState } from 'vuex'

const initialData = () => ({
  items: [],
  newDealProduct: {},
  newDealItem: {
    inventory_id: 0,
    inventory: {},
    quantity: 1,
    revenue: 0,
    profit: 0,
    price: 0
  },
  monthlyCost: 0,
  deduction: 0,
  start_date: moment().toDate(),
  end_date: moment().add(1, 'year').toDate(),
  comms_perc: 0,
  // comms_perc: this.current.user.comms_perc,
  user_id: 0
})

export default {
  name: 'DealModal',
  mixins: [currentUser],
  components: { InventorySearch },
  props: {
    id: String,
    value: Object
  },
  created () {
    this.permissions = permissions
    this.fields = [
      { key: 'name', label: 'Product', tdClass: 'align-middle' },
      { key: 'quantity', label: 'Quantity', tdClass: 'align-middle' },
      { key: 'price', label: 'Unit Price', tdClass: 'align-middle' },
      { key: 'revenue', label: 'Equipment Net Cost', tdClass: 'align-middle' },
      { key: '_', label: '' }
    ]
    if (this.currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)) {
      this.fields.splice(4, 0, { key: 'profit', label: 'Profit', tdClass: 'align-middle' })
    }
  },
  data () {
    return initialData()
  },
  computed: {
    ...mapState('authentication', ['current']),
    totalCommission () {
      return ((this.totalProfit > 0 && this.comms_perc > 0) ? (this.totalProfit / 100) * this.comms_perc : 0).toFixed(2)
    },
    dealRevenue () {
      return (this.items.reduce((aggr, item) => aggr + (item.quantity * item.price), 0)).toFixed(2)
    },
    totalRevenue () {
      return (this.monthlyCost * Math.ceil(Math.abs(moment(this.end_date).diff(this.start_date, 'month', true)))).toFixed(2)
    },
    totalProfit () {
      return ((this.monthlyCost * Math.ceil(Math.abs(moment(this.end_date).diff(this.start_date, 'month', true)))) - this.dealRevenue).toFixed(2)
    }
  },
  methods: {
    addItem () {
      this.items.push(this.newDealItem)
      this.newDealProduct = {}
      this.newDealItem = {
        ...this.newDealItem,
        quantity: 1,
        profit: 0,
        price: 0
      }
    },
    removeItem (index) {
      this.items.splice(index, 1)
    },
    submitDeal () {
      this.$emit('update', {
        id: this.value && this.value.id,
        items: this.items.map(item => ({
          id: item.id || null,
          inventory_id: item.inventory_id,
          inventory: item.inventory,
          quantity: item.quantity,
          revenue: item.revenue,
          profit: item.profit,
          price: item.price
        })),
        start_date: moment(this.start_date).format('YYYY-MM-DD'),
        end_date: moment(this.end_date).format('YYYY-MM-DD'),
        comms_perc: this.comms_perc || this.current.user.comms_perc || 0,
        commission: this.totalCommission,
        deduction: this.deduction,
        monthly_cost: this.monthlyCost,
        revenue: this.totalRevenue,
        profit: this.totalProfit,
        user: this.user || this.current.user,
        user_id: this.user_id || this.current.user.id
      })
      this.items = []
      this.comms_perc = 0
      this.user_id = 0
    }
  },
  watch: {
    dealRevenue () {
      this.monthlyCost = (this.dealRevenue / 52 * 14).toFixed(2)
    },
    'newDealProduct' () {
      this.newDealItem.inventory_id = this.newDealProduct.id
      this.newDealItem.inventory = this.newDealProduct
      this.newDealItem.price = this.newDealProduct.price
    },
    'newDealItem.quantity' () {
      this.newDealItem.revenue = this.newDealItem.quantity * this.newDealItem.price
      this.newDealItem.profit = this.newDealItem.quantity * (this.newDealItem.price - this.newDealProduct.cost)
    },
    'newDealItem.price' () {
      this.newDealItem.revenue = this.newDealItem.quantity * this.newDealItem.price
      this.newDealItem.profit = this.newDealItem.quantity * (this.newDealItem.price - this.newDealProduct.cost)
    },
    'value' (value) {
      if (value) {
        this.items = value.items.map(item => ({
          id: item.id,
          inventory_id: item.inventory.id,
          inventory: item.inventory,
          quantity: item.quantity,
          revenue: item.revenue,
          profit: item.profit,
          price: item.price
        })) || []

        this.start_date = value.start_date
        this.end_date = value.end_date

        this.deduction = value.deduction.value
        this.monthlyCost = value.monthly_cost
        this.comms_perc = value.comms_perc
        this.user_id = value.user_id
        this.user = value.user
      } else {
        Object.assign(this.$data, initialData())
      }
    }
  }
}
</script>
