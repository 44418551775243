<template>
  <b-table bordered responsive striped head-variant="dark" :fields="fields" :items="items">
    <template #cell(_)="{ index }">
      <b-button block @click="$emit('edit', index)" variant="primary">Edit Deal</b-button>
    </template>
  </b-table>
</template>

<script>
import permissions from '../../permissions.json'
import currentUser from '../../mixins/currentUser'
import moment from 'moment'

export default {
  name: 'DealsTable',
  mixins: [currentUser],
  created () {
    this.permissions = permissions
    if (this.currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)) {
      this.fields.splice(4, 0, { key: 'profit', label: 'Profit', tdClass: 'align-middle' })
    }
  },
  data () {
    return {
      fields: [
        { key: 'items', label: 'Items', tdClass: 'align-middle' },
        { key: 'startDate', label: 'Start Date', tdClass: 'align-middle' },
        { key: 'endDate', label: 'End Date', tdClass: 'align-middle' },
        { key: 'revenue', label: 'Customer Cost Over', tdClass: 'align-middle' },
        { key: 'commission', label: 'Estimated Commission', tdClass: 'align-middle' },
        { key: 'monthly_cost', label: 'Monthly Cost', tdClass: 'align-middle' },
        { key: 'user', label: 'Created By', tdClass: 'align-middle' },
        { key: '_', text: '' }
      ]
    }
  },
  props: {
    value: Array
  },
  computed: {
    items () {
      return this.value.map(deal => ({
        user: `${deal.user.forename} ${deal.user.surname}`,
        items: `${deal.items.reduce((a, i) => a + i.quantity, 0)} ${deal.items.reduce((a, i) => a + i.quantity, 0) !== 1 ? 'items' : 'item'}`,
        startDate: moment(deal.start_date).format('DD/MM/YYYY'),
        endDate: moment(deal.end_date).format('DD/MM/YYYY'),
        revenue: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(deal.revenue),
        profit: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(deal.profit),
        commission: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(deal.commission),
        monthly_cost: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(deal.monthly_cost)
      }))
    }
  }
}
</script>
